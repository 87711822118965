import { Dayjs } from 'dayjs'
import { isEmpty } from 'lodash'

import { programList } from 'app/config'
import { CARTV2_TYPE, RECHARGE_REASON } from 'app/constants'
import { batchTypesMap } from 'app/pages/Class/Inventory/constants'
import { transformRechargeSlabs } from 'app/pages/User/Parent/forms/helper'
import { sentryLogger } from 'sentry'

import { apiEndpoints, apiURLOrigin } from './common'

import { makeRequestUrl, canAddReferralCode } from '../app/helpers'
import { get, patch, post, remove, put } from '../request'

const membershipRequestStructure = (data) => ({
  user: data.user,
  plan: data.plan,
  start_date: data.start_date,
  referral_code: data.referral_code,
  dummy_first_cycle: data.dummy_first_cycle
})

const fetchPlans = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(apiEndpoints.plans, queries)

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching plans')
    throw error
  }
}

const createSubscriptions = async (data) => {
  try {
    const response = await post(
      apiEndpoints.subscriptions,
      membershipRequestStructure(data)
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating subscription')
    throw error
  }
}

const extendSubscription = async (data) => {
  try {
    const response = await post(`${apiEndpoints.subscriptions}extend/`, {
      subscription: data.subscription,
      end_date: data.end_date
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating subscription')
    throw error
  }
}

const cancelSubscription = async (data) => {
  try {
    const response = await post(`${apiEndpoints.subscriptions}cancel/`, {
      subscription: data.subscription,
      reason: data.reason,
      refund: data.process_refund === 'true' ? true : false,
      zero_balance: data.zero_balance === 'true' ? true : false,
      status: 'cancelled'
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating subscription')
    throw error
  }
}

const pauseSubscription = async (data) => {
  try {
    const response = await post(`${apiEndpoints.subscriptions}cancel/`, {
      subscription: data.subscription,
      follow_up_date: data.follow_up_date,
      refund: data.process_refund === 'true' ? true : false,
      zero_balance: data.zero_balance === 'true' ? true : false,
      reason: data.reason,
      status: 'pause'
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in creating subscription')
    throw error
  }
}

const fetchWalletData = async (data) => {
  try {
    const response = await get(
      makeRequestUrl(`${apiEndpoints.memberships}/coinbook/`, {
        parent_uuid: data.parent
      })
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in wallet data')
    throw error
  }
}

const fetchTransactionData = async (data) => {
  try {
    const response = await get(
      makeRequestUrl(`${apiEndpoints.transaction}`, {
        user: data.parent
      })
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in transaction data')
    throw error
  }
}

const fetchAddCoinsData = async (data) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.rechargePlans}`, {
    status: 'active',
    app_name: 'alfred'
  })
  try {
    const response = await get(requestUrl)
    return response?.data
  } catch (error) {
    sentryLogger(error, 'error in add coins config')
    throw error
  }
}

const fetchReasonsData = async (data) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: 'RECHARGE_REASON'
  })

  try {
    const response = await get(requestUrl)

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in reasons config')
    throw error
  }
}

const topUpSubscription = async (data) => {
  try {
    const topUpObject = {
      user: data.uuid,
      type: CARTV2_TYPE.TOPUP,
      payment_mode: 'rupees',
      value: data.actual_amount,
      ...(data.use_referral_code && canAddReferralCode(data)
        ? { referral_code: data.referral_code }
        : null),
      notes: data.notes,
      items: [
        {
          discount: data.discount_percentage,
          cost_price: data.list_price,
          reason: data.reason,
          product: 'coins'
        }
      ],
      reason_for_using_alfred: data.reason_for_using_alfred
    }
    //is_policy_acknowledged_alfred key will only be added and set to true if
    //it is originally false and reason is Recharge
    if (
      data.reason === RECHARGE_REASON.RECHARGE &&
      !data.is_policy_acknowledged_alfred
    )
      topUpObject.is_policy_acknowledged_alfred = true

    const response = await post(apiEndpoints.cartV2, topUpObject)
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in topup subscription')
    throw error
  }
}

const deductCoinsSubscription = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.memberships}/coinbook/adjustment/`,
      {
        user: data.uuid,
        value: data.coins,
        reason: data.reason,
        status: 'adjustment',
        type: 'deduct',
        notes: data.notes
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in deduct coins')
    throw error
  }
}

const renewSubscriptionNow = async (data) => {
  try {
    const response = await post(
      `${apiEndpoints.memberships}/subscriptions/renew/`,
      {
        parent_uuid: data.uuid
      }
    )

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in renew now')
    throw error
  }
}

const changePlanSubscription = async (data) => {
  try {
    const response = await patch(`${apiEndpoints.subscriptions}`, {
      user: data.uuid,
      plan: data.plan
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in changing plan')
    throw error
  }
}

const getRefundAmount = async (data) => {
  try {
    const response = await post(`${apiEndpoints.subscriptions}cancel/refund/`, {
      parent: data.uuid,
      coins: data.remaining_coins
    })

    return response.data
  } catch (error) {
    sentryLogger(error, 'error in changing plan')
    throw error
  }
}

const suggestSchedule = async (data) => {
  const resp = await Promise.all(
    data.scheduleSuggestions.map(async (item, index) => {
      try {
        const response = await post(`${apiEndpoints.batch_suggestion}`, {
          course: item.course,
          user: data.uuid,
          suggestion: `${item.ideal_timings.trim()} **${item.other_timings.trim()} **${item.child_name.trim()}`
        })

        return response.data
      } catch (error) {
        sentryLogger(error, 'error in schedule suggestion')
        throw error
      }
    })
  )
  return resp[0]
}

const fetchScheduleSuggestion = async (data) => {
  try {
    const response = await get(
      makeRequestUrl(`${apiEndpoints.batch_suggestion}`, {
        user__uuid: data.parent
      })
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in fetching schedule suggestion')
    throw error
  }
}

const programEnrolment = async (data) => {
  let payloadObj = {
    program: data?.program?.uuid,
    student: data.student?.user.uuid
  }
  if (data?.program?.type === programList.exporation) {
    payloadObj = {
      ...payloadObj,
      start_date: data?.start_date,
      end_date: data?.program_end_date
    }
  } else if (data?.program?.type === programList.summer_explore) {
    payloadObj = {
      ...payloadObj,
      start_date: data?.duration?.start_date,
      end_date: data?.duration?.end_date,
      exploration_camp_data: {
        start_time: data?.time_slot?.start_time,
        teacher: data?.time_slot?.teacher_id,
        centre: data?.time_slot?.centreId
      }
    }
  } else if (data?.program?.type === batchTypesMap.excel) {
    payloadObj = {
      ...payloadObj,
      start_date: data?.batchInfo[data?.batch_uuid]?.startDate?.label,
      end_date: data?.batchInfo[data?.batch_uuid]?.endDate?.label,
      batch: data?.batch_uuid,
      is_discovery: data?.batchInfo[data?.batch_uuid]?.isDiscover || false
    }
  }
  try {
    const response = await post(
      `${apiEndpoints.programs}/enrolment`,
      payloadObj
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in renew now')
    throw error
  }
}

const getCustomerData = async (key) => {
  const requestUrl = makeRequestUrl(`${apiEndpoints.configApp}`, {
    key: key,
    app_name: 'alfred'
  })

  try {
    const response = await get(requestUrl)

    return response.data.results[0].value
  } catch (error) {
    sentryLogger(error, 'error in terminate enrolment')
    throw error
  }
}

const updateParentPhoneNumber = async (data) => {
  try {
    const response = await put(
      `${apiURLOrigin}/oh_users/parent/${data.uuid}/`,
      {
        user: {
          phone_number: data?.new_phone_number
        },
        force_update_number: data?.force_update_number
      }
    )
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in updating user')
    throw error
  }
}

const createUserDeletionRequest = async (data) => {
  try {
    const response = await post(`${apiURLOrigin}/oh_users/deletion_request`, {
      phone_number: data.newPhoneNumber,
      reason: data.deletion_reason,
      deletion_failure_reason: data.errorMessage
    })
    return response.data
  } catch (error) {
    sentryLogger(error, 'error in raising user deletion request')
    throw error
  }
}

const membershipActions = async (data, action) => {
  try {
    let res
    switch (action) {
      case 'extend':
        //subscription.uuid
        res = await extendSubscription({
          ...data
        })
        break
      case 'topup':
        //success true
        res = await topUpSubscription({
          ...data
        })
        break
      case 'change_plan':
        res = await changePlanSubscription({
          ...data
        })
        break
      case 'deduct':
        //success true
        res = await deductCoinsSubscription({
          ...data
        })
        break
      case 'cancel':
        //success true
        if (data.cancel_type === 'pause')
          res = await pauseSubscription({
            ...data
          })
        else
          res = await cancelSubscription({
            ...data
          })
        break
      case 'renew_now':
        //success true
        res = await renewSubscriptionNow({
          ...data
        })
        break
      case 'suggest_schedule':
        res = await suggestSchedule({
          ...data
        })
        break
      case 'enrolment':
        res = await programEnrolment({
          ...data
        })
        break
      case 'change_phone_number':
        res = await updateParentPhoneNumber({ ...data })
        break
      case 'parent_number_deletion_request':
        res = await createUserDeletionRequest({ ...data })
        break
      default:
        break
    }

    return res
  } catch (error) {
    sentryLogger(error)
    throw error
  }
}

const fetchCanceledMemberPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.subscriptions}cancel/`, queries)

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          ...(item?.pay_in
            ? {
                pay_in: {
                  ...item.pay_in,
                  created: item?.pay_in?.created
                    ? new Dayjs(item?.pay_in?.created).format('YYYY-MM-DD')
                    : null
                }
              }
            : null)
        }
      })
    }
  } catch (error) {
    sentryLogger(error, 'error in getting paid payment')
    throw error
  }
}

const fetchPausedMemberPayment = async ({ url, queries } = {}) => {
  const requestUrl = !isEmpty(url)
    ? url
    : makeRequestUrl(`${apiEndpoints.subscriptions}cancel/`, {
        ...queries,
        status: 'pause'
      })

  try {
    const response = await get(requestUrl)

    return {
      ...response.data,
      results: response.data.results.map((item) => {
        return {
          ...item,
          ...(item?.pay_in
            ? {
                pay_in: {
                  ...item.pay_in,
                  created: item?.pay_in?.created
                    ? new Dayjs(item?.pay_in?.created).format('YYYY-MM-DD')
                    : null
                }
              }
            : null)
        }
      })
    }
  } catch (error) {
    sentryLogger(error, 'error in getting paused payment info')
    throw error
  }
}

export {
  fetchPlans,
  createSubscriptions,
  extendSubscription,
  topUpSubscription,
  renewSubscriptionNow,
  fetchWalletData,
  fetchAddCoinsData,
  fetchReasonsData,
  deductCoinsSubscription,
  changePlanSubscription,
  cancelSubscription,
  pauseSubscription,
  getRefundAmount,
  fetchCanceledMemberPayment,
  fetchPausedMemberPayment,
  membershipActions,
  suggestSchedule,
  fetchScheduleSuggestion,
  programEnrolment,
  getCustomerData,
  updateParentPhoneNumber,
  createUserDeletionRequest,
  fetchTransactionData
}
